(function ($) {
  $(window).on("load", function () {});
  var equalHeight = function () {
    $('.why-item h5').css('height', 'auto');
    var maxHeight = 0;
    $('.why-item h5').each(function () {
      if ($(this).height() > maxHeight) {
        maxHeight = $(this).height();
      }
    });
    $('.why-item h5').css('height', maxHeight);
  };
  $(document).ready(function () {
    var a = ($(window).innerWidth() - $('.header .container').innerWidth()) / 2 + 1;
    $('.header .top-header .side-bg').css('width', a);
    $('#nav-icon1').click(function () {
      $(this).toggleClass('open');
      $('.main-menu').slideToggle();
    });
    equalHeight();
    $('.sliderShow_3').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      arrows: true,
      dots: false,
      autoplay: true,
      autoplaySpeed: 4000,
      prevArrow: '<button class="slick-prev"><i class="fa-solid fa-angle-left"></i></button>',
      nextArrow: '<button class="slick-next"><i class="fa-solid fa-angle-right"></i></button>',
      responsive: [{
        breakpoint: 1399,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
      ]
    });

    $('.testimonial_slider').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      arrows: true,
      dots: false,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
      ]
    });

    $('.client_slider').slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      infinite: true,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [{
        breakpoint: 1499,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 479,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
      ]
    });

    $('.why-icon img , .imgTosvg img').each(function () {
      var $img = jQuery(this);
      var imgID = $img.attr('id');
      var imgClass = $img.attr('class');
      var imgURL = $img.attr('src');
      jQuery.get(imgURL, function (data) {
        var $svg = jQuery(data).find('svg');
        if (typeof imgID !== 'undefined') {
          $svg = $svg.attr('id', imgID);
        }
        if (typeof imgClass !== 'undefined') {
          $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }
        $svg = $svg.removeAttr('xmlns:a');
        if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
          $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
        }
        $img.replaceWith($svg);
      }, 'xml');
    });
  });

  //parallax scroll
  $(window).scroll(function () {
    if ($(this).scrollTop() > 60) {
      $('.header').addClass("sticky");
    } else {
      $('.header').removeClass("sticky");
    }
  });
  $(window).on('resize', function () {
    var a = ($(window).innerWidth() - $('.header .container').innerWidth()) / 2 + 1;
    $('.header .top-header .side-bg').css('width', a);
    equalHeight();
  });
})(jQuery);